<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 400px 400px;"></spinner>
    </v-row>

    <v-row class="mx-2" style="margin-top: 10px" v-else>
      <v-col cols="12" xs="12" style="margin-top: 30px">
        <v-col cols="12" sm="11">
          <v-toolbar
            style="margin-top: -30px"
            color="transparent"
            flat
            elevation="0"
            class=" title "
            height="55px"
          >
            <v-col cols="12" sm="8" md="8" lg="8">
              <v-card-text
                class="mb-n2"
                style="font-size: 16px; margin-left: -38px; font-weight: bold; "
                ><a
                  style="margin:auto;font-size: 18px;"
                  class="blue-grey--text"
                  @click="move"
                >
                  <v-icon color="484e52" class="mt-n1">mdi-chevron-left</v-icon>
                  <h4 style="color: #484e52">
                    Order Reference: {{ details.orderReference }}
                  </h4></a
                >
              </v-card-text>
            </v-col>
          </v-toolbar>
        </v-col>
        <div
          style="margin: 5px;"
          class="d-flex flex-wrap justify-space-around mb-6"
          color="transparent"
          flat
        >
          <!--          <v-alert style="filter: brightness(1); border-radius:10px" type="success"  >-->
          <!--            <v-div-->
          <!--                height="100"-->
          <!--                width="250"-->
          <!--                class="pa-2 my-2 mx-2 d-flex flex-column justify-center"-->
          <!--                color="white"-->
          <!--                outlined-->
          <!--            >-->
          <!--              <div>-->
          <!--                <v-icon size="30" color="#47173a">{{ success }}</v-icon>-->
          <!--              </div>-->
          <!--              <div style="margin-left: 30px">-->
          <!--                <h3 style="font-size: 20px;" class="white&#45;&#45;text">  {{ details.netAmount | converter }}</h3>-->
          <!--              </div>-->
          <!--              <div class="ml-10 white&#45;&#45;text font-weight-medium">-->
          <!--                Net Amount-->
          <!--              </div>-->
          <!--            </v-div></v-alert>-->

          <v-card class="container1">
            <div class="box1 container2">
              <div class="box2">Date: {{ details.date }}</div>
              <div class="box2"><v-icon>mdi-dots-vertical</v-icon></div>
            </div>
            <div class="container3">
              <h4 style="color:#484e52">Net Amount</h4>
              <h1 style="color: #3aa1d6">
                {{ details.netAmount | converter }}
              </h1>
            </div>
            <div class="box1"></div>
          </v-card>
          <v-card class="container1">
            <div class="box1 container2">
              <div class="box2" style="text-decoration-color: #737373">
                ON: {{ details.orderNumber }}
              </div>
              <div class="box2"><v-icon>mdi-dots-vertical</v-icon></div>
            </div>
            <div class="container3">
              <h4 style="color:#484e52">Order Reviewed</h4>
              <h1 style="color: #3aa1d6">
                {{ details.isOrderReviewed ? "Done" : "No" }}
              </h1>
            </div>
            <div class="box1"></div>
          </v-card>

          <v-card class="container1">
            <div class="box1 container2">
              <div class="box2" style="text-decoration-color: #737373">
                DN: {{ details.deliveryNumber }}
              </div>
              <div class="box2"><v-icon>mdi-dots-vertical</v-icon></div>
            </div>
            <div class="container3">
              <h4 style="color:#484e52">Delivery Generated</h4>
              <h1 style="color: #3aa1d6">
                {{ details.isDeliveryGenerated ? "Done" : "No" }}
              </h1>
            </div>
            <div class="box1"></div>
          </v-card>
          <v-card class="container1">
            <div class="box1 container2">
              <div class="box2" style="text-decoration-color: #737373">
                TP: {{ details.tripNumber }}
              </div>
              <div class="box2"><v-icon>mdi-dots-vertical</v-icon></div>
            </div>
            <div class="container3">
              <h4 style="color:#484e52">Order Completed</h4>
              <h1 style="color: #3aa1d6">
                {{ details.isOrderCompleted ? "Done" : "No" }}
              </h1>
            </div>
            <div class="box1"></div>
          </v-card>

          <!--          <ReviewCard-->
          <!--              title="Order Review"-->
          <!--              color-type="success"-->
          <!--              count="100%"-->
          <!--              :checkType=" details.isOrderReviewed ? 'success': 'error'"-->
          <!--              description="Total Inventory"-->
          <!--          />-->
        </div>

        <!--        <v-card height="200px" style="margin-top: 40px" class="mx-2" outlined>-->
        <!--        </v-card>-->
      </v-col>
      <v-col cols="12" sm="12">
        <v-card elevation="2" class="mt-n6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left primary white--text">Order Details</th>
                  <th class="text-left primary white--text">
                    Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-left">Product Name</td>
                  <td>{{ details.productId.name }}</td>
                </tr>
                <tr>
                  <td>Order Number (Source)</td>
                  <td>{{ details.orderNumber }}</td>
                </tr>
                <tr>
                  <td>Trip Number</td>
                  <td>{{ details.tripNumber }}</td>
                </tr>
                <tr>
                  <td>Delivery Number</td>
                  <td>{{ details.deliveryNumber }}</td>
                </tr>
                <tr>
                  <td>Invoice Number</td>
                  <td>{{ details.invoiceNumber }}</td>
                </tr>
                <tr>
                  <td>Loading Date</td>
                  <td>{{ details.loadingDate }}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{{ details.branchId.telephoneNumber }}</td>
                </tr>
                <tr>
                  <td>Branch</td>
                  <td>{{ details.branchId.name }}</td>
                </tr>
                <tr>
                  <td>Cheque Number</td>
                  <td>{{ details.chequeId.number }}</td>
                </tr>
                <tr>
                  <td>Net Amount</td>
                  <td>{{ details.netAmount | converter }}</td>
                </tr>
                <tr>
                  <td>Cheque Amount</td>
                  <td>{{ details.chequeId.amount | converter }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Spinner from "vue-simple-spinner";
import axios from "axios";
//import ReviewCard from "@/components/ReviewCard";

export default {
  name: "ReviewedDetails",
  components: { Spinner },
  data() {
    return {
      ready: false,
      details: {}
    };
  },

  filters: {
    converter(value) {
      return "GHS " + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  },

  methods: {
    check(value) {
      return value ? "success" : "warning";
    },
    move() {
      this.$router.push({
        name: "master.reviewed"
      });
    },

    getDetails() {
      let id = this.$route.params.id;
      // console.log(id);
      this.ready = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/orders/line/item/${id}`
      })
        .then(response => {
          this.ready = false;
          if (response.status === 200) {
            this.details = response.data;
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    }
  },

  created() {
    this.getDetails();
  }
};
</script>

<style scoped>
.container1 {
  display: flex;
  height: 150px;
  width: 250px;
  background-color: #f2fbff;
  flex-direction: column;
  wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.box1 {
  height: auto;
  width: auto;
  padding: 3%;
}

.container2 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.box2 {
  height: auto;
  width: auto;
  padding: 5%;
}

.container3 {
  display: flex;
  flex-direction: column;
  wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}
</style>
